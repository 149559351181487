import { useContext } from 'react';
import { constants, roles } from '../../../constants';
import { TransactionType } from '../../../types/amr-pipeline/enums/TransactionType';
import { AmrTransaction } from '../../../types/amr-pipeline/models/AmrTransaction';
import { OriginatingTransaction } from '../../../types/amr-pipeline/models/OriginatingTransaction';
import { dateTimeUtils } from '../../../utils';
import { OnHoverTooltip } from '../../common';
import { Transaction } from '../../../types/amr-pipeline/models/Transaction';
import { TransactionStatus } from '../../../types/amr-pipeline/enums/TransactionStatus';
import IconSVG from '../../../styles/svg-icons';
import { NewDebutAmrLabel } from '../common/NewDebutAmrLabel';
import IssuanceMonitorBreadcrumbs from '../IssuanceMonitorBreadcrumbs';
import { TransactionHistoryLink } from '../common/TransactionHistoryLink';
import { DealUpdatesPopover } from '../aggregated/DealUpdatesPopover';
import { isBrokerDealersOwnTransaction } from '../../../utils/amr-pipeline.utils';
import { Company } from '../../../types/amr-pipeline/models/Company';
import { IntexButton } from '../aggregated/IntexButton/IntexButton';
import { ShowFor } from '../../access';
import TransactionContext from '../transactionContext';
import { user } from '../../../user';
import { AmrClass } from '../../../types/amr-pipeline/models/AmrClass';
import { BloombergLink } from '../../common/BloombergLink';
import { ValitanaLink } from '../../common/ValitanaLink';
import { TransactionAlertsButton } from '../common/transactionAlerts/TransactionAlertsButton';
import { AddToPortfolioLink } from '../common/AddToPortfolioLink';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RelatedTransDropdown } from '../common/RelatedTransDropdown';


const allRolesWithoutArrangersClient = roles.getAllRolesExclude(roles.ArrangersClient);

export enum HeaderAction {
    SyndicateContacts,
    Intex,
    ShareTransaction,
    Alerts
}

interface HeaderProps {
    selectedTransaction: OriginatingTransaction | AmrTransaction;
    currentClass?: AmrClass;
    onActionClick: (action: HeaderAction) => void;
    isClassActive?: boolean;
    userCompany?: Company;
}

const Header = ({
    selectedTransaction,
    currentClass,
    onActionClick,
    isClassActive = false,
    userCompany,
}: HeaderProps) => {
    const isArrangerBrokerDealer = isBrokerDealersOwnTransaction(selectedTransaction, userCompany);
    const { limitedAccess } = useContext(TransactionContext);

    const {
        referenceName,
        dealReferenceName,
        dealLegalName,
        status,
        type,
    } = selectedTransaction;
    const { syndicateContacts } = selectedTransaction as OriginatingTransaction;

    const withSyndicateContacts = !!syndicateContacts?.length;
    const isOriginatingTransaction = type !== TransactionType.amr;

    const isArrangersClient = user.hasSingleRole(roles.ArrangersClient);

    const formatLastUpdated = (transaction: Transaction, format = constants.dateFormat) =>
        transaction.lastUpdated
            ? dateTimeUtils.utcToLocalString(transaction.lastUpdated, format)
            : constants.emptyPlaceholder;

    const renderLastUpdated = (transaction: Transaction) => {
        return (
            <ActionBlocker
                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                overrideRequiredFeatures={isArrangersClient}
            >
                {blocked => {
                    const {
                        referenceName,
                        dealReferenceName,
                        dealLegalName,
                        lastUpdated,
                        type,
                        status,
                        version,
                        dealUpdates,
                    } = transaction as OriginatingTransaction;

                    return (
                        <DealUpdatesPopover
                            disabled={blocked}
                            withOverlayPrefix
                            historyLinkTarget="_self"
                            referenceName={referenceName}
                            dealReferenceName={dealReferenceName}
                            dealLegalName={dealLegalName}
                            lastUpdated={lastUpdated}
                            type={type}
                            status={status}
                            version={version}
                            dealUpdates={dealUpdates}
                        />
                    );
                }}
            </ActionBlocker>
        );
    };

    const renderTitle = () => {
        return !isOriginatingTransaction && currentClass?.name ? `Class ${currentClass.name}` : dealLegalName;
    };

    return (
        <>
        <div className="sub-header sub-header-new-issue">

            <div className="sub-header-row type01">
                <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.Administrator, roles.DataEntry, roles.CollateralManager]}>
                    <IssuanceMonitorBreadcrumbs
                        selectedTransaction={selectedTransaction}
                        isClassActive={isClassActive}
                    />
                </ShowFor>
                <div className="flex-row">
                    <NewDebutAmrLabel transaction={selectedTransaction} />
                    <OnHoverTooltip overlay={renderTitle()} wrapperClass="tooltip-title">
                        <h1>{renderTitle()}</h1>
                    </OnHoverTooltip>
                    <div className="flex-item-right right-controls">
                        {isOriginatingTransaction && (
                            <>
                                {isArrangerBrokerDealer && (
                                    <OnHoverTooltip overlay="Share Transaction">
                                        <button
                                            className="btn-link"
                                            onClick={() => onActionClick(HeaderAction.ShareTransaction)}
                                        >
                                            <IconSVG name="share" width={16} height={16} />
                                            Share
                                        </button>
                                    </OnHoverTooltip>
                                )}
                                {status !== TransactionStatus.Closed && (
                                    <div className="select-alert-wrap">
                                        <TransactionAlertsButton
                                            transaction={selectedTransaction}
                                            withLabel
                                            onClick={() => onActionClick(HeaderAction.Alerts)}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="flex-row controls-bottom-row">
                    <div className="header-bwic-rules">
                        {[
                            TransactionStatus.Active,
                            TransactionStatus.Priced,
                            TransactionStatus.Draft,
                            TransactionStatus.OnHold,
                            TransactionStatus.Closed,
                        ].includes(status)
                            ? renderLastUpdated(selectedTransaction)
                            : formatLastUpdated(selectedTransaction)}
                    </div>

                    {isOriginatingTransaction && status !== TransactionStatus.Draft && (
                        <div className="header-bwic-rules">
                            <ActionBlocker
                                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                                overrideRequiredFeatures={isArrangersClient}
                            >
                                {blocked => (
                                    <TransactionHistoryLink
                                        target="_self"
                                        disabled={blocked}
                                        overlayText="Transaction History"
                                        referenceName={referenceName}
                                        dealReferenceName={dealReferenceName}
                                        version={selectedTransaction.version}
                                    />
                                )}
                            </ActionBlocker>
                        </div>
                    )}
                    <RoleActionBlocker roles={allRolesWithoutArrangersClient}>
                        {blockedByRole => (
                            <ActionBlocker
                                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                                overrideRequiredFeatures={blockedByRole}
                            >
                                {blocked => (
                                    <div className="header-bwic-rules">
                                        <RelatedTransDropdown
                                            disabled={blockedByRole || blocked}
                                            transaction={selectedTransaction}
                                        />
                                    </div>
                                )}
                            </ActionBlocker>
                        )}
                    </RoleActionBlocker>
                    {currentClass && (
                        <AddToPortfolioLink
                            wrapperClassName="header-bwic-rules"
                            transaction={selectedTransaction}
                            transactionClass={currentClass}
                            checkSubscription
                            withText
                            withEmptyPlaceholder={false}
                        />
                    )}
                    {isOriginatingTransaction && !limitedAccess && (
                            <div className="header-bwic-rules">
                                <OnHoverTooltip
                                    overlay={"There are no syndicate contacts"}
                                    disabled={withSyndicateContacts}
                                    placement="bottomRight"
                                >
                                    <button
                                        className="btn-link"
                                        disabled={!withSyndicateContacts}
                                        onClick={
                                            withSyndicateContacts
                                                ? () => onActionClick(HeaderAction.SyndicateContacts)
                                                : undefined
                                        }
                                    >
                                        <IconSVG name="syndicate" width={16} height={16} />
                                        Syndicate
                                    </button>
                                </OnHoverTooltip>
                            </div>
                    )}
                    <div className="header-bwic-rules">
                        <IntexButton
                            label="Intex"
                            userCompany={userCompany}
                            transaction={selectedTransaction}
                            onClick={() => onActionClick(HeaderAction.Intex)}
                            asyncFileLoad={false}
                        />
                    </div>
                    {currentClass && (
                        <div className="header-bwic-rules header-bwic-bloomberg">
                            <BloombergLink
                                identifiers={currentClass.class} textLink
                            />
                        </div>
                    )}
                    {currentClass && (
                        <div className="header-bwic-rules">
                            <ValitanaLink
                                identifiers={currentClass.class}
                                textLink
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    );
};

export default Header;
