import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isNil } from 'lodash';
import { Preloader } from "../../common";
import { amrPipelineCommonActions, amrTransactionHistoryActions } from '../../../actions'
import { routes } from "../../../constants";
import { AppState } from "../../../types/state/AppState";
import { TransactionsTabTypes } from "../types/TransactionsTabTypes";
import { Redirect, useHistory, useParams } from "react-router";
import { TransactionHistorySelectionPanel } from './TransactionHistorySelectionPanel';
import { PipelineHistoryState } from '../../../types/state/PipelineHistoryState';
import Header from './Header';
import { OriginatingTransactionDetails } from '../detailed/new-issue/OriginatingTransactionDetails';
import { HistoryInfo } from './HistoryInfo';
import TransactionContext from '../transactionContext';
import { canViewTransactionDetails, hasLimitedAccess } from '../../../utils/amr-pipeline.utils';
import { PipelineCommonState } from '../../../types/state/PipelineCommonState';

interface RouteParams {
    transactionReferenceName: string;
    dealReferenceName: string;
    tab?: TransactionsTabTypes;
    version?: string;
}

export const PipelineHistory = () => {
    const { dealReferenceName, transactionReferenceName, tab, version } = useParams<RouteParams>();

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        history: transactionsHistory,
        allHistoryClassesList,
        isLoading
    } = useSelector<AppState, PipelineHistoryState>(
        (state: AppState) => state.issuanceMonitor.amrPipelineHistory
    );

    const { userCompany } = useSelector<AppState, PipelineCommonState>(
        state => state.issuanceMonitor.amrPipelineCommon
    );

    const selectedVersion = version ? parseInt(version, 10) : undefined;
    const selectedTab = tab || TransactionsTabTypes.overview;

    const selectedTransactionHistory = transactionsHistory?.find(x => x.version === selectedVersion);

    useEffect(() => {
        if (!userCompany) {
            dispatch(amrPipelineCommonActions.loadUserCompanyRequest());
        }

        dispatch(amrTransactionHistoryActions.transactionHistoryRequest(dealReferenceName, transactionReferenceName));

        return () => {
            dispatch(amrTransactionHistoryActions.reset());
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (transactionsHistory?.length) {
            const [firstHistoryItem] = transactionsHistory;

            if (isNil(selectedVersion)) {
                history.replace(routes.transactionHistoryUrl(transactionReferenceName, dealReferenceName, tab, firstHistoryItem.version));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionsHistory, dealReferenceName, transactionReferenceName]);

    useEffect(() => {
        if (isNil(selectedVersion) || isNil(selectedTransactionHistory) || selectedTransactionHistory.transaction.isDetailsLoaded) {
            return;
        }

        dispatch(
            amrTransactionHistoryActions.loadTransactionDetais(
                selectedVersion,
                dealReferenceName,
                transactionReferenceName,
                selectedTransactionHistory?.transaction?.arranger?.referenceName
            )
        );
    }, [
        dispatch,
        dealReferenceName,
        transactionReferenceName,
        selectedVersion,
        selectedTransactionHistory,
        selectedTransactionHistory?.transaction.arranger?.referenceName
    ]);

    const handleViewTransaction = (version: number) => {
        history.replace(routes.transactionHistoryUrl(transactionReferenceName, dealReferenceName, tab, version));
    };

    const handleTabChange = (tab: string) => {
        history.replace(routes.transactionHistoryUrl(transactionReferenceName, dealReferenceName, tab, version));
    };

    if (isLoading && (!transactionsHistory?.length || !selectedTransactionHistory)) {
        return <Preloader inProgress={isLoading} />;
    }

    if (!transactionsHistory?.length) {
        return <Redirect to={routes.notFound} />;
    }

    if (!selectedTransactionHistory) {
        return false;
    }

    const { transaction } = selectedTransactionHistory;

    const contextValue = {
        canView: canViewTransactionDetails(),
        limitedAccess: hasLimitedAccess(transaction, userCompany),
        accessTracking: false,
        analyticsAccess: false,
        invitedClientsAccess: false,
        ioisAccess: false,
    };

    return (
        <TransactionContext.Provider value={contextValue}>
            <div className="container container-history">
                <div className="container-flex container-sidebar">
                    <TransactionHistorySelectionPanel
                        allHistoryClassesList={allHistoryClassesList}
                        userCompany={userCompany}
                        selectedItem={selectedTransactionHistory}
                        history={transactionsHistory}
                        isLoading={false}
                        onChange={handleViewTransaction}
                    />
                    <div className="container-flex content-part-sidebar container-new-issue container-trans-history">
                        <HistoryInfo
                            versionTitle={selectedTransactionHistory.versionTitleFull}
                            referenceName={transactionReferenceName}
                            dealReferenceName={dealReferenceName}
                        />
                        <Header versionDateTime={selectedTransactionHistory.eventDateTime} selectedTransaction={transaction} />
                        <OriginatingTransactionDetails
                            isLoading={isLoading}
                            transaction={transaction}
                            disabled={isLoading}
                            activeTab={selectedTab}
                            difference={selectedTransactionHistory.diff}
                            onNavigate={handleTabChange}
                        />
                    </div>
                </div>
            </div>
        </TransactionContext.Provider>
    );
};
