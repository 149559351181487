import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import queryString from 'query-string';
import { Preloader } from "../../common";
import { amrPipelineDetailedActions, imUserConfigActions } from "../../../actions/";
import Header, { HeaderAction } from "./Header";
import { roles, routes } from "../../../constants";
import { TransactionType } from "../../../types/amr-pipeline/enums/TransactionType";
import { AmrTransaction } from "../../../types/amr-pipeline/models/AmrTransaction";
import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { AppState } from "../../../types/state/AppState";
import { PipelineDetailedState } from "../../../types/state/PipelineDetailedState";
import { TransactionsTabTypes } from "../types/TransactionsTabTypes";
import { AmrDealTabTypes } from '../types/AmrDealTabTypes';
import { AmrDealTrancheTabTypes } from '../types/AmrDealTrancheTabTypes'
import { Redirect, useLocation, useParams } from "react-router";
import { TransactionsSelectionPanel } from "./TransactionsSelectionPanel";
import { AmrDetails } from './amr/AmrDetails';
import { OriginatingTransactionDetails } from './new-issue/OriginatingTransactionDetails';
import { IntexPopup } from './IntexPopup';
import { SyndicatePopup } from '../aggregated/SyndicatePopup';
import { canViewTransactionDetails, hasAnalyticsAccess, hasInvitedClientsAccess, hasIOIsAccess, hasLimitedAccess } from '../../../utils/amr-pipeline.utils';
import TransactionContext from '../transactionContext';
import { InviteClientsTransactionPopup } from "../common/InviteClientsTransactionPopup/InviteClientsTransactionPopup";
import { PipelineDetailedTabTypes } from "../types/PipelineDetailedTabTypes";
import { TransactionAlertsPopup } from "../common/transactionAlerts/TransactionAlertsPopup";
import { usePortfolios } from "../../../effects/usePortfolios";
import { isRequestFailed, isRequestSuccess } from "../../../utils";
import { user } from "../../../user";
import { useAppDispatch } from "../../../effects/useAppDispatch";

interface RouteParams {
    transactionReferenceName: string;
    dealReferenceName: string;
    classReferenceName?: string;
    tab?: TransactionsTabTypes | AmrDealTabTypes | AmrDealTrancheTabTypes;
}

export const PipelineDetailed = () => {
    const { classReferenceName, dealReferenceName, transactionReferenceName, tab: activeTab  } = useParams<RouteParams>();
    const dispatch = useAppDispatch();
    const {
        transactions,
        loadedTransactionsWithDetails,
        initialTransaction,
        isTransactionsRequesting,
        isIOIsSubmitting,
        isTransactionDetailsRequesting,
        searchTerm,
        hasMoreTransactions,
        numberOfHiddenTransactions,
    } = useSelector<AppState, PipelineDetailedState>(
        (state: AppState) => state.issuanceMonitor.amrPipelineDetailed
    );

    const location = useLocation();

    const isPortfolioDisabled = user.hasRoles(roles.ArrangersClient);

    const { requestState } = usePortfolios(isPortfolioDisabled);
    const isPortfoliosLoaded = isPortfolioDisabled || isRequestSuccess(requestState) || isRequestFailed(requestState);

    const { userCompany } = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon);

    const [showIntextPopup, setShowIntexPopup] = useState(false);
    const [showSyndicatePopup, setShowSyndicatePopup] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showAlertsPopup, setShowAlertsPopup] = useState(false);

    const [selectionPanelTab, setSelectionPanelTab] = useState(PipelineDetailedTabTypes.all);
    const { token } = queryString.parse(location.search, { decode: false });

    const selectedTransaction = useMemo(
        () =>
            loadedTransactionsWithDetails?.find(
                t => t.referenceName === transactionReferenceName && t.dealReferenceName === dealReferenceName,
            ) ||
            transactions?.find(
                t => t.referenceName === transactionReferenceName && t.dealReferenceName === dealReferenceName,
            ) ||
            initialTransaction,
        [transactions, initialTransaction, transactionReferenceName, loadedTransactionsWithDetails, dealReferenceName],
    );


    const syndicateContacts =  selectedTransaction && selectedTransaction.type !== TransactionType.amr ?
        {
            arrangerName: selectedTransaction?.arranger?.legalName,
            contacts: (selectedTransaction as OriginatingTransaction).syndicateContacts,
        } : null;

    const transactionsLoaded = transactions != null;
    const currentAmrClass = (selectedTransaction as AmrTransaction)?.classes.find(c => c.referenceName === classReferenceName);

    const contextValue = useMemo(() => ({
        canView: canViewTransactionDetails(),
        limitedAccess: hasLimitedAccess(selectedTransaction, userCompany),
        accessTracking: true,
        analyticsAccess: hasAnalyticsAccess(selectedTransaction, userCompany),
        invitedClientsAccess: hasInvitedClientsAccess(selectedTransaction, userCompany),
        ioisAccess: hasIOIsAccess(selectedTransaction, userCompany),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [
        selectedTransaction?.referenceName,
        userCompany?.referenceName
    ]);

    React.useEffect(() => {
        dispatch(imUserConfigActions.getUserConfig());

        return () => {
            dispatch(amrPipelineDetailedActions.reset())
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (!userCompany || transactionsLoaded || isTransactionsRequesting || initialTransaction) {
            return;
        }

        dispatch(amrPipelineDetailedActions.init(dealReferenceName, transactionReferenceName, token as string));
    }, [dispatch, dealReferenceName, transactionReferenceName, token, userCompany, transactionsLoaded, isTransactionsRequesting, initialTransaction]);

    const handleHeaderActionClick = (action: HeaderAction) => {
        switch (action) {
            case HeaderAction.Intex:
                setShowIntexPopup(true);
                break;
            case HeaderAction.SyndicateContacts:
                setShowSyndicatePopup(true);
                break;
            case HeaderAction.ShareTransaction:
                setShowSharePopup(true);
                break;
            case HeaderAction.Alerts:
                setShowAlertsPopup(true);
                break;
            default:
                break;
        }
    };

    if (selectedTransaction) {
        document.title = `${selectedTransaction.dealLegalName} - KTX ATS Platform`;
    }

    if (!transactionsLoaded || !selectedTransaction || !isPortfoliosLoaded) {
        return <Preloader inProgress={true} />;
    }

    if (transactionReferenceName && dealReferenceName) {
        if (!initialTransaction) {
            return <Preloader inProgress={true} />;
        }
    }

    if (!transactions) {
        return <Redirect to={routes.notFound} />;
    }

    return (
        <TransactionContext.Provider value={contextValue}>
            <div className="container">
                <div className="container-flex container-sidebar">
                    <TransactionsSelectionPanel
                        initialTransaction={initialTransaction}
                        isLoading={isTransactionsRequesting}
                        isSubmittingIoIs={isIOIsSubmitting}
                        selectedClassReferenceName={classReferenceName}
                        searchTerm={searchTerm}
                        transactions={transactions}
                        selectedTransaction={selectedTransaction}
                        hasMoreTransactions={hasMoreTransactions}
                        activeTab={selectionPanelTab}
                        onTabChange={setSelectionPanelTab}
                        numberOfHiddenTransactions={numberOfHiddenTransactions}
                    />
                    <div className="container-flex content-part-sidebar container-new-issue">
                        <Header
                            selectedTransaction={selectedTransaction}
                            currentClass={currentAmrClass}
                            onActionClick={handleHeaderActionClick}
                            isClassActive={!!currentAmrClass}
                            userCompany={userCompany}
                        />
                        {selectedTransaction.type === TransactionType.amr
                            ?
                                <AmrDetails
                                    transaction={selectedTransaction as AmrTransaction}
                                    classReferenceName={classReferenceName}
                                    activeTab={activeTab as AmrDealTabTypes}
                                />
                            :
                                <OriginatingTransactionDetails
                                    isLoading={isTransactionDetailsRequesting || isIOIsSubmitting}
                                    transaction={
                                        selectedTransaction as OriginatingTransaction
                                    }
                                    activeSelectionPanelTab={selectionPanelTab}
                                    activeTab={activeTab as TransactionsTabTypes}
                                />
                        }
                    </div>
                </div>
                {showIntextPopup &&
                    <IntexPopup
                        transaction={selectedTransaction as OriginatingTransaction}
                        onCloseClick={() => setShowIntexPopup(false)}
                    />
                }
                {(showSyndicatePopup && syndicateContacts) &&
                    <SyndicatePopup
                        syndicateContacts={syndicateContacts}
                        onClose={() => setShowSyndicatePopup(false)}
                    />
                }
                {showSharePopup && (
                    <InviteClientsTransactionPopup
                        transaction={selectedTransaction}
                        onClose={() => setShowSharePopup(false)}
                    />
                )}
                {showAlertsPopup && (
                    <TransactionAlertsPopup transaction={selectedTransaction} onClose={() => setShowAlertsPopup(false)} />
                )}
            </div>
        </TransactionContext.Provider>
    );
};
