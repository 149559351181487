import React from "react";
import { ScrollSyncPane } from "react-scroll-sync";
import { constants } from "../../../../../constants";
import { OriginatingTransactionClass } from "../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { moneyUtils } from "../../../../../utils";
import {
    SecurityListRow,
    SecurityListColumn,
} from "../../../../bidding/common";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";

interface Props {
    classes: OriginatingTransactionClass[];
    columns: ColumnBuilder<OriginatingTransactionClass>[];
    sticky?: boolean;
}

export function TotalBalance({ classes, columns, sticky }: Props) {
    let totalBalance: number | null = null;
    classes.map((c: OriginatingTransactionClass) => {
        if (c.balance != null) {
            if (totalBalance != null) totalBalance = totalBalance + c.balance;
            else totalBalance = c.balance;
        }

        return c;
    });

    const formattedTotalBalance = totalBalance ? moneyUtils.money(totalBalance) : constants.emptyPlaceholder;

    return sticky ? (
        <SecurityListRow className="data-list-striped-total">
            <ScrollSyncPane group={"vertical"}>
                <div className="data-column-sticky total-sticky-col data-column-sticky-left">
                    {columns.map((c: any, index: number) => {
                        if (index === 0)
                            return (
                                <SecurityListColumn key="total" className="data-list-cell-sm">
                                    Total:
                                </SecurityListColumn>
                            );
                        else return null;
                    })}
                </div>
            </ScrollSyncPane>
            <ScrollSyncPane group={"horizontal"}>
                <div className="data-column-sticky total-sticky-col data-column-sticky-middle">
                    <div className="middle-sticky-content">
                        <div className="data-list-striped-row flex-row">
                            <SecurityListColumn className="data-list-cell-sm text-right">
                                {formattedTotalBalance}
                            </SecurityListColumn>
                        </div>
                    </div>
                </div>
            </ScrollSyncPane>
        </SecurityListRow>
    ) : (
        <div className="data-list-row flex-row data-list-striped-total">
            <div className="data-list-cell data-list-cell-total">Total</div>
            <div className="data-list-cell data-list-cell-total-value text-right">{formattedTotalBalance}</div>
        </div>
    )
}
