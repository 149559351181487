import { memo, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { DragFileZone } from "../../../common/DragFileZone";
import { isEqual } from "lodash";
import { SelectFile } from "../../../grid/SelectFile";
import IconSVG from "../../../../styles/svg-icons";
import { errorActions } from "../../../../actions";
import { constants, errorMessages } from "../../../../constants";
import { AmrFile } from "../../../../types/amr-pipeline/models/AmrFile";
import { amrPipelineService } from "../../../../services/amr-pipeline.service";

interface UploadingProgress {
    [fileName: string]: number;
}

export interface FilesUploaderChildProps {
    referenceName?: string;
    documents: AmrFile[];
    progress: UploadingProgress;
    handleDeleteFile: (
        documentReferenceName: string
    ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface Props {
    referenceName?: string;
    documents: AmrFile[];
    maxFileSizeInMb?: number;
    onChange: (documents: AmrFile[]) => void;
    onUploading: (uploading: boolean) => void;
    children: (props: FilesUploaderChildProps) => React.ReactNode;
}

const acceptedExtensions = [
    "csv",
    "doc",
    "docx",
    "pdf",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
];

function DocumentListComponent({
    referenceName,
    documents,
    maxFileSizeInMb = constants.documentMaxFilesizeInMb,
    children,
    onChange,
    onUploading,
}: Props) {
    const dispatch = useDispatch();
    const uploadedDocuments = useRef<AmrFile[]>([]);
    const [dragEnter, setDragEnter] = useState(false);
    const [uploadingFileNames, setUploadingFileNames] = useState<string[]>([]);
    const [progress, setProgress] = useState<UploadingProgress>({});

    const acceptedExtensionsList = acceptedExtensions
        .map((extension) => `.${extension}`)
        .join(", ");

    const onUploadDocument = async (file: File) => {
        try {
            const response = await amrPipelineService.uploadDocument(
                file,
                (progressEvent: ProgressEvent) =>
                    setProgress((progress) => ({
                        ...progress,
                        [file.name]: Math.floor(
                            (progressEvent.loaded / progressEvent.total) * 100
                        ),
                    }))
            );

            return response[0];
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    };

    const uploadFile = async (file: File) => {
        setUploadingFileNames((names) => [...names, file.name]);

        const newDocument = await onUploadDocument(file);

        if (newDocument) {
            uploadedDocuments.current.push(newDocument);
        }

        setUploadingFileNames((names) => {
            return names.filter((name) => name !== file.name);
        });
    };

    useEffect(() => {
        onUploading(!!uploadingFileNames.length);
    }, [onUploading, uploadingFileNames.length]);

    useEffect(() => {
        if (uploadedDocuments.current.length && !uploadingFileNames.length) {
            onChange([...uploadedDocuments.current, ...documents]);
            uploadedDocuments.current = [];
        }
        // eslint-disable-next-line
    }, [uploadedDocuments, uploadingFileNames]);

    const handleDrop = (files: FileList) => {
        if (!files) {
            return;
        }

        [...files].some((file) => {
            const splitFile = file.name?.split(".");
            const fileExtension = splitFile[splitFile.length - 1];

            const isInvalidSize =
                file.size > 1024 * 1024 * maxFileSizeInMb;
            const isInvalidExtension =
                acceptedExtensions &&
                !acceptedExtensions.some(
                    (ext) => ext.toLowerCase() === fileExtension.toLowerCase()
                );

            if (isInvalidSize) {
                dispatch(
                    errorActions.error(
                        "",
                        errorMessages.documentMaxFileSizeMessageText(
                            maxFileSizeInMb
                        ),
                        errorMessages.documentMaxFileSizeMessageTitle
                    )
                );
                return true;
            }
            if (isInvalidExtension) {
                dispatch(
                    errorActions.error(
                        "",
                        errorMessages.documentInvalidFileTypeText(
                            fileExtension,
                            acceptedExtensions.map((ext) => `.${ext}`).join(",")
                        ),
                        errorMessages.documentInvalidFileTypeTitle
                    )
                );
                return true;
            }
            uploadFile(file);
            return isInvalidExtension || isInvalidSize;
        });
    };

    const handleDeleteFile =
        (documentReferenceName: string) =>
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            let documentList = documents;

            if (!referenceName) {
                documentList = documents.filter(
                    (document) =>
                        document.referenceName !== documentReferenceName
                );
            } else {
                documentList = documents.reduce(
                    (accum: AmrFile[], document) => {
                        if (document.referenceName === documentReferenceName) {
                            return document.uploadTime
                                ? [...accum, { ...document, remove: true }]
                                : accum;
                        }

                        return [...accum, { ...document }];
                    },
                    []
                );
            }

            onChange(documentList);
        };

    const getTableData = (): AmrFile[] => {
        const documentsToDisplay = [
            ...uploadingFileNames.map((name) => ({
                name,
                referenceName: "",
                uploadTime: new Date(),
            })),
            ...uploadedDocuments.current,
            ...documents,
        ] as AmrFile[];

        return documentsToDisplay.filter(({ remove }) => !remove);
    };

    return (
        <div className="data-item-row data-item-row-full-height">
            <div className="data-item-col">
                <div
                    className={cn("documents-section", {
                        "drag-enter-content": dragEnter,
                    })}
                >
                    <DragFileZone
                        onFiles={handleDrop}
                        onDragEnter={() => setDragEnter(true)}
                        onDragLeave={() => setDragEnter(false)}
                        className="component-file-upload"
                    >
                        <div className="component-file-upload-placeholder">
                            {dragEnter ? (
                                <>Uploading...</>
                            ) : (
                                <>
                                    <IconSVG
                                        name="upload-doc"
                                        width={24}
                                        height={24}
                                    />
                                    <div className="text-left">
                                        <div className="main-text file-upload-main-text">
                                            Drag & Drop file here or&nbsp;
                                            <SelectFile
                                                onFiles={handleDrop}
                                                acceptedExtensions={
                                                    acceptedExtensionsList
                                                }
                                            />
                                        </div>
                                        <span className="footnote">
                                            Format: {acceptedExtensionsList}{" "}
                                            (max{" "}
                                            {maxFileSizeInMb}
                                            MB)
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </DragFileZone>
                    {children({
                        referenceName,
                        documents: getTableData(),
                        progress,
                        handleDeleteFile,
                    })}
                </div>
            </div>
        </div>
    );
}

export const FileUploader = memo(
    DocumentListComponent,
    (prevProps: Props, nextProps: Props) =>
        isEqual(prevProps.documents, nextProps.documents)
);
